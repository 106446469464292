.app-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.content-container {
  display: flex;
  gap: 20px;
}

.user-list {
  width: 30%;
}

.user-list ul {
  list-style-type: none;
  padding: 0;
}

.user-list li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.user-list li:hover {
  background-color: #f0f0f0;
}

.user-list li.selected {
  background-color: #e1f5fe;
}

.conversation-container {
  width: 70%;
}

.chat-interface {
  height: 500px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.human {
  background-color: #e1f5fe;
  align-self: flex-start;
}

.assistant {
  background-color: #e8f5e9;
  align-self: flex-end;
  margin-left: auto;
}

.message-content {
  margin-bottom: 5px;
}

.timestamp {
  font-size: 0.8em;
  color: #666;
  text-align: right;
}

/* Add these to your existing App.css */

.feedback-section {
  margin-top: 5px;
}

.feedback-count {
  margin-left: 10px;
  font-size: 0.8em;
  color: #666;
}

.feedback-form {
  margin-top: 10px;
}

.feedback-form input,
.feedback-form textarea {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
}

.feedback-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.feedback-form button:hover {
  background-color: #45a049;
}

/* Add this to your App.css */

.auth-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* Add to your existing App.css */

.content-container {
  display: flex;
  gap: 20px;
}

.user-list {
  width: 20%;
}

.conversation-container {
  width: 40%;
}

.feedback-container {
  width: 40%;
}

.feedback-list {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.add-feedback input,
.add-feedback textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.feedback-item {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #eee;
}

.examples-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.examples-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  position: relative;
}


button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button.close-button, .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background-color: #ff9900 !important;
  color: white;
  border: none;
  cursor: pointer;
}

.add-example, .example-item {
  background-color: #f0f0f0;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.add-example input, .edit-example input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.examples-list {
  max-height: calc(100% - 200px);
  overflow-y: auto;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section input {
  width: 70%;
  padding: 5px;
  margin-right: 10px;
}

.col-left{
  width: 20%;
}
.col-right{
  width: 80%;
}
a{
  color: #1766ce;
  cursor: pointer;
  font-weight: 800;
}
.bg-light{
  background-color: darkgrey;
}
.bg-red{
  background-color: firebrick;
  color: #eee;
}

.btn-dark{
  background-color: #666;
  color: #eee;
}

.blood-test-results {
  margin: 20px;
}

.blood-test {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
}

.biomarkers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.biomarkers-table th, .biomarkers-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.biomarkers-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.biomarkers-table tr:nth-child(even) {
  background-color: #f9f9f9;
}